@font-face {
  font-family: "UML";
  src: url("./fonts/uml/UML.eot?b7ib94");
  src: url("./fonts/uml/UML.eot?b7ib94#iefix") format("embedded-opentype"),
    url("./fonts/uml/UML.ttf?b7ib94") format("truetype"),
    url("fonts/uml/UML.woff?b7ib94") format("woff"),
    url("./fonts/uml/UML.svg?b7ib94#UML") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.i-logo-UML {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "UML" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo-UML:before {
  content: "\e900";
}
