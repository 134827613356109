@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@500&family=Young+Serif&display=swap");

/* font-family: 'Oswald', sans-serif; */
/* font-family: 'Young Serif', serif; */
.navbar-img {
  width: 50px;
}

.default-page {
  min-height: 100vh;
}

/* .page-main {
  min-height: 100vh;
} */
.main-sec-contact-geo {
  display: flex;
  flex-direction: row;
}

.main-sec-skills-items {
  display: flex;
  flex-direction: column;
}

.main-sec-programs-items {
  display: flex;
  flex-direction: column;
}

.resume-iframe {
  display: none;
  height: 90vh;
  width: 80%;
}

@media (min-width: 1200px) {
  .resume-img {
    display: none;
  }
  .resume-iframe {
    display: block;
  }
}

/* BackGround Colors */

/* Greens */
.bg-green-1 {
  background-color: #008e40;
}

/* Greys / Blacks */
.bg-black-1 {
  background-color: #000000;
}

.bg-black-2 {
  background-color: #131313;
}

/* BackGround Texture */
.bg-default-1 {
  background-size: 110% 105%;
  background-color: #0000009d;
}

.bg-texture-1 {
  background-image: url("../../../public/images/bg-textures/bg-texture-3.jpg");
  background-blend-mode: multiply;
  background-color: #000e04f8;
  background-size: 100% 100%;
}

/* Main Bg app.js */
.static-bg {
  background-attachment: fixed;
  background-image: url("../../../public/images/bg-textures/bg-texture-2.jpg");
  background-blend-mode: multiply;
  background-color: #ffffffab;
  background-size: 100% 100%;
}

/* Font Colors */
.text-white {
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.65);
}

.text-white-2 {
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.65);
  color: rgb(212, 212, 212);
}

.text-lime {
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.65);
  color: rgb(0, 255, 0);
}

.link-lime {
  color: rgb(20, 209, 20);
  text-decoration: underline;
  font-family: "Oswald", sans-serif;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.65);
}

.link-lime:hover {
  color: #00a542;
}

.link-lime:focus {
  color: #00a542;
}

.link-red {
  color: rgb(255, 55, 55);
  text-decoration: none;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.65);
}

.link-red:hover {
  color: rgb(255, 80, 80);
}

.link-red:focus {
  color: rgb(255, 80, 80);
}

/* Fonts */
.font-1 {
  font-family: "Young Serif", serif;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.65);
}

.font-2 {
  font-family: "Oswald", sans-serif;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.65);
}

/* hr tags */
.hr-1 {
  color: rgb(82, 82, 82);
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.65);
  opacity: 100%;
}

.text-indent-1 {
  text-indent: 0px;
}

.hide-500 {
  display: block;
}
.offset-40 {
  padding-left: 40px;
}

@media (min-width: 500px) {
  .text-indent-1 {
    text-indent: 40px;
  }
  .hide-500 {
    display: none;
  }
  .offset-40 {
    padding-left: 50px;
  }
}

.btn-cGreen {
  background-image: url("../../../public/images/bg-textures/bg-texture-2.jpg");
  background-blend-mode: difference;
  background-color: rgb(30, 150, 30);
  background-size: 100% 100%;
}

.btn-cGreen:hover {
  background-image: url("../../../public/images/bg-textures/bg-texture-2.jpg");
  background-blend-mode: difference;
  background-color: rgba(35, 93, 35, 0.904);
  background-size: 100% 100%;
}

.btn-cGreen:focus {
  background-image: url("../../../public/images/bg-textures/bg-texture-2.jpg");
  background-blend-mode: difference;
  background-color: rgba(35, 93, 35, 0.904);
  background-size: 100% 100%;
}
