@font-face {
  font-family: "MongoDB";
  src: url("./fonts/mongodb/MongoDB.eot?l07rtp");
  src: url("./fonts/mongodb/MongoDB.eot?l07rtp#iefix") format("embedded-opentype"),
    url("./fonts/mongodb/MongoDB.ttf?l07rtp") format("truetype"),
    url("./fonts/mongodb/MongoDB.woff?l07rtp") format("woff"),
    url("./fonts/mongodb/MongoDB.svg?l07rtp#MongoDB") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.i-logo-MongoDB {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "MongoDB" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo-MongoDB:before {
  content: "\e900";
}
