@font-face {
  font-family: "VSCode";
  src: url("./fonts/vscode/VSCode.eot?nl7aic");
  src: url("./fonts/vscode/VSCode.eot?nl7aic#iefix") format("embedded-opentype"),
    url("./fonts/vscode/VSCode.ttf?nl7aic") format("truetype"),
    url("./fonts/vscode/VSCode.woff?nl7aic") format("woff"),
    url("./fonts/vscode/VSCode.svg?nl7aic#VSCode") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.i-logo-VSCode {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "VSCode" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo-VSCode:before {
  content: "\e900";
  /* color: #0078cb; */
}
