@font-face {
  font-family: "MicroOffice";
  src: url("./fonts/microOffice/MicroOffice.eot?ksve2w");
  src: url("./fonts/microOffice/MicroOffice.eot?ksve2w#iefix") format("embedded-opentype"),
    url("./fonts/microOffice/MicroOffice.ttf?ksve2w") format("truetype"),
    url("./fonts/microOffice/MicroOffice.woff?ksve2w") format("woff"),
    url("./fonts/microOffice/MicroOffice.svg?ksve2w#MicroOffice") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.i-logo-MicroOffice {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "MicroOffice" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo-MicroOffice:before {
  content: "\e900";
}
