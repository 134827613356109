@font-face {
    font-family: 'SQL';
    src:  url('./fonts/sql/SQL.eot?h5dsfw');
    src:  url('./fonts/sql/SQL.eot?h5dsfw#iefix') format('embedded-opentype'),
      url('./fonts/sql/SQL.ttf?h5dsfw') format('truetype'),
      url('./fonts/sql/SQL.woff?h5dsfw') format('woff'),
      url('./fonts/sql/SQL.svg?h5dsfw#SQL') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
.i-logo-SQL {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'SQL' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .logo-SQL:before {
    content: "\e900";
  }
  