@font-face {
    font-family: 'CSharp';
    src:  url('./fonts/csharp/CSharp.eot?loxgk4');
    src:  url('./fonts/csharp/CSharp.eot?loxgk4#iefix') format('embedded-opentype'),
      url('./fonts/csharp/CSharp.ttf?loxgk4') format('truetype'),
      url('./fonts/csharp/CSharp.woff?loxgk4') format('woff'),
      url('./fonts/csharp/CSharp.svg?loxgk4#CSharp') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
.i-logo-CSharp {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'CSharp' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .logo-CSharp:before {
    content: "\e900";
  }
  